<template>
	<ReportCard 
		title="Ranking de Mesas" 
		:width="384"
		:height="300"
	>
		<a-table 
			:columns="columns" 
			:data-source="data"
			size="middle"
			style="width: 100%;" 
		/>
	</ReportCard>
</template>

<script>
import ReportCard from './reportCard'

const columns = [
  {
    title: '#',
    dataIndex: 'position',
  },
  {
    title: 'Mesa',
	dataIndex: 'place',
	align: 'center',
  },
  {
	title: 'nº de pedidos',
    dataIndex: 'orders',
	align: 'center',
  },
  {
    title: 'Valor total',
    dataIndex: 'total',
  },
];
const data = [
  {
	key: '1',
	position: '1',
    place: '11',
    orders: 535,
    total: 'R$ 10.127,89',
  },
  {
	key: '2',
	position: '2',
    place: '03',
    orders: 445,
    total: 'R$ 8.999,19',
  },
  {
	key: '3',
	position: '3',
    place: '09',
    orders: 421,
    total: 'R$ 8.207,09',
  },
];

export default {
	name: 'TablesRankingCard',
	components: {
		ReportCard,
	},
	data() {
		return {
			data,
			columns,
		}
	},
}
</script>