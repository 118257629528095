<template>
	<a-card
		v-if="loading"
		:body-style="{ padding: '16px' }"
		style="border-radius: 5px;"
	>
		<a-space direction="vertical" style="width: 100%;" size="middle">
			<a-skeleton :title="false" :paragraph="{ rows: 2 }" active />
			<a-skeleton
				:title="false"
				:paragraph="{ rows: 5, width: '100%' }"
				active
			/>
		</a-space>
	</a-card>
	<a-card
		v-else
		:body-style="{ padding: '16px' }"
		style="border-radius: 5px;"
	>
		<a-row class="header" type="flex" justify="space-between" align="top">
			<a-col><h1>Resumo geral das avaliações</h1></a-col>
			<a-col>
				<span style="display: flex; justify-content: flex-end;">
					<a-space align="center">
						<a-rate
							:value="Math.round(resume.rating / 0.5) * 0.5"
							style="font-size: 16px;"
							disabled
							allow-half
						/>
						<span style="font-size: 14px;">{{
							resume.rating
						}}</span>
					</a-space>
				</span>
			</a-col>
		</a-row>
		<p class="subheader">
			Total de {{ resume.rating_amount }}
			{{ resume.rating_amount === 1 ? 'avaliação' : 'avaliações' }}
		</p>
		<a-space direction="vertical" style="width: 100%;">
			<a-row
				v-for="rating in ratingsByStars"
				:key="rating.stars"
				type="flex"
				justify="space-between"
				align="middle"
				:gutter="16"
			>
				<a-col flex="82px">
					<span class="stars-span">
						{{ rating.stars }}
						{{ rating.stars === 1 ? 'estrela' : 'estrelas' }}
					</span>
				</a-col>
				<a-col flex="auto">
					<a-progress
						:stroke-color="strokeColor"
						:percent="rating.percent"
						:stroke-width="16"
					>
						<template #format="percent">
							<span class="extra">{{ percent }}%</span>
						</template>
					</a-progress>
				</a-col>
			</a-row>
		</a-space>
	</a-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'RatingsResume',
	data() {
		return {
			loading: false,
			resume: undefined,
			strokeColor: {
				'0%': '#feb47b',
				'100%': '#ff7e5f',
			},
		}
	},
	computed: {
		...mapGetters({
			selectedMerchantId: 'merchants/selectedMerchantId',
		}),
		ratingsByStars: function () {
			const { ratings_by_stars } = this.resume
			const sortByStars = (a, b) => b.stars - a.stars
			return ratings_by_stars?.sort(sortByStars) || []
		},
	},
	watch: {
		$route: {
			handler: 'getData',
			immediate: true,
		},
	},
	methods: {
		...mapActions('merchants', ['getRatingsResume']),
		async getData() {
			try {
				this.loading = true
				this.resume = await this.getRatingsResume(
					this.selectedMerchantId
				)
			} catch (error) {
				console.log(error)
			} finally {
				this.loading = false
			}
		},
	},
}
</script>

<style lang="less" scoped>
.container {
	border-radius: 5px;

	.header {
		font-size: 16px;
		color: rgba(0, 0, 0, 0.65);
		height: 24px;
	}
	.subheader {
		margin-bottom: 16px;
		font-size: 14px;
		color: rgba(0, 0, 0, 0.5);
	}
}
.extra {
	display: flex;
	justify-content: flex-end;
}

.stars-span {
	font-weight: 600;
}
</style>
