<template>
	<a-card class="card" :body-style="{ padding: '16px' }">
		<a-row type="flex" justify="start" :gutter="12">
			<a-col span="9">
				<img style="width: 82px;" :src="invoiceImage" />
			</a-col>
			<a-col span="15">
				<a-row type="flex" justify="space-between">
					<a-col>
						<span style="font-weight: 501;">
							{{
								`Fatura de ${moment(invoice.due_date).format(
									'MMMM'
								)}`
							}}
						</span>
					</a-col>
					<a-col class="info">
						<a-tooltip>
							<template slot="title">
								Detalhar fatura
							</template>
							<a-icon
								type="info-circle"
								@click="$emit('detail', invoice.due_date)"
							/>
						</a-tooltip>
					</a-col>
				</a-row>
				<a-row>
					<span :style="statusStyle">
						{{ getStatus() }}
					</span>
				</a-row>
				<a-row>
					<MoneyValue
						:value="value"
						:size="18"
						style="float: right;"
					/>
				</a-row>
				<a-row>
					<span class="expiration">
						{{
							`Vencimento ${moment(invoice.due_date).format(
								'DD/MM'
							)}`
						}}
					</span>
				</a-row>
			</a-col>
		</a-row>
	</a-card>
</template>

<script>
import { MoneyValue } from '@/modules/partner/components'

export default {
	name: 'InvoiceCard',
	components: {
		MoneyValue,
	},
	props: {
		invoice: {
			type: Object,
			required: true,
		},
	},
	computed: {
		invoiceImage: function () {
			if (this.invoice.calculated) return 'img/invoice_open.png'
			else if (this.moment() > this.moment(this.invoice.due_date))
				return 'img/overdue_invoice.png'
			else return 'img/invoice_closed.png'
		},
		statusStyle: function () {
			var COLOR = {
				Aberta: '#888888',
				Fechada: 'orange',
				'Em Atraso': '#fa1e0e',
			}

			return {
				fontSize: '12px',
				fontWeight: 'bold',
				color: COLOR[this.getStatus()],
				lineHeight: 0.75,
			}
		},
		value: function () {
			const add = [
				'to_agiliza_value',
				'to_agiliza_cashback',
				'to_agiliza_canceled',
			]
			const subtract = ['to_merchant_value', 'to_merchant_cashback']

			let value = add.reduce((sum, curr) => sum + this.invoice[curr], 0)
			value -= subtract.reduce((sum, curr) => sum + this.invoice[curr], 0)

			return value > 0 ? value : 0
		},
	},
	methods: {
		getStatus() {
			if (this.invoice.calculated) return 'Aberta'
			else if (this.moment() > this.moment(this.invoice.due_date))
				return 'Em Atraso'
			else return 'Fechada'
		},
	},
}
</script>

<style lang="less" scoped>
.card {
	border-radius: 5px;
	width: 292px;
	height: 119px;

	.image {
		width: 82px;
	}

	.expiration {
		font-size: 11px;
		font-weight: 500;
		color: rgba(0, 0, 0, 0.45);
		line-height: 0.5;
		float: right;
		margin-top: 4px;
		line-height: 0.5;
	}

	.info {
		color: rgba(0, 0, 0, 0.6);
		cursor: pointer;

		&:hover {
			color: rgba(0, 0, 0, 0.85);
		}
	}
}
</style>
