<template>
	<ReportCard
		title="Este mês"
		:width="300"
		:height="250"
		:footer="footer"
		date-mode="month"
		:loading="loading"
	>
		<div style="display: block; width: 100%;">
			<span class="description">
				R$
				<span class="description-total-value">
					{{ totalValue }}
				</span>
			</span>
			<a-icon
				v-if="indicator"
				:type="caret.type"
				:style="{ color: caret.color }"
			/>
		</div>
		<VeHistogram
			:data="chartData"
			:settings="chartSettings"
			width="100%"
			:extend="chartExtend"
			:colors="colors"
		/>
	</ReportCard>
</template>

<script>
import ReportCard from './reportCard'
import VeHistogram from 'v-charts/lib/histogram.common'
import { axios } from '@/utils/axios'
import { mapGetters } from 'vuex'

export default {
	name: 'MonthlyCard',
	components: {
		ReportCard,
		VeHistogram,
	},
	data() {
		this.chartExtend = {
			grid: {
				show: false,
				top: 0,
				height: '130px',
				width: '300px',
				left: -36,
			},
			legend: {
				show: false,
			},
			yAxis: {
				show: false,
			},
		}
		return {
			loading: false,
			cardData: {},
			indicator: null,
			chartSettings: {
				metrics: ['dinheiro', 'cartão'],
				stack: { sales: ['dinheiro', 'cartão'] },
			},
			chartData: {
				columns: ['dia', 'app', 'dinheiro', 'cartão'],
				rows: [],
			},
			colors: ['#ffb980', '#6be6c1', '#3fb1e6', '#e01f54', '#c4ebad'],
		}
	},
	computed: {
		...mapGetters({
			isMerchantActive: 'merchants/isMerchantActive',
			isAuthorized: 'merchants/isAuthorized',
			selectedMerchantId: 'merchants/selectedMerchantId',
		}),
		caret: {
			set: function (values) {
				const { current, previus } = values

				if (current === previus) this.indicator = null
				else if (current > previus) {
					this.indicator = {
						type: 'caret-up',
						color: '#87d068',
					}
				} else {
					this.indicator = {
						type: 'caret-down',
						color: '#f50',
					}
				}
			},
			get: function () {
				return this.indicator
			},
		},
		totalValue: function () {
			const total = this.chartData.rows.reduce((sum, curr) => {
				return (sum +=
					parseFloat(curr.app) +
					parseFloat(curr['cartão']) +
					parseFloat(curr['dinheiro']))
			}, 0)

			return new Intl.NumberFormat('pt-BR', {
				style: 'currency',
				currency: 'BRL',
			})
				.format(total)
				.replace(/R\$/g, '')
		},
		dailyAvg: function () {
			let count = 0
			const sum = this.chartData.rows.reduce((sum, curr) => {
				const total =
					parseFloat(curr.app) +
					parseFloat(curr['cartão']) +
					parseFloat(curr['dinheiro'])
				if (total > 0) count++

				return (sum += total)
			}, 0)

			const total = count > 0 ? sum / count : 0

			return new Intl.NumberFormat('pt-BR', {
				style: 'currency',
				currency: 'BRL',
			})
				.format(total)
				.replace(/R\$/g, '')
		},
		footer: function () {
			return {
				label: 'Média diária',
				total: this.dailyAvg,
			}
		},
	},
	beforeMount() {
		if (this.isAuthorized && this.isMerchantActive) this.getData()
	},
	methods: {
		async getData() {
			try {
				this.loading = true

				const url = `v1/merchants/${this.selectedMerchantId}/reports/monthly_financial/`
				// const params = this.getTodayRange()

				this.cardData = await axios.get(url /*, { params: params }*/)
				this.chartData.rows = [
					...this.cardData.monthly_financial_report,
				]
			} catch (err) {
				//
			} finally {
				this.loading = false
			}
		},
	},
}
</script>

<style lang="less" scoped>
.description {
	font-size: 12px;
	color: rgba(0, 0, 0, 0.45);
	line-height: 1;

	.description-total-value {
		font-size: 18px;
		font-weight: bold;
		color: rgba(0, 0, 0, 0.75);
	}
}
</style>
