<template>
	<ReportCard title="Histórico de faturas" :width="632" :height="400">
		<InvoicesHistoryTable
			:data="invoices"
			@detail="(key) => $emit('detail', key)"
		/>
	</ReportCard>
</template>

<script>
import ReportCard from './reportCard'
import InvoicesHistoryTable from './invoicesHistoryTable'

export default {
	name: 'InvoicesHistoryCard',
	components: {
		ReportCard,
		InvoicesHistoryTable,
	},
	props: {
		invoices: {
			type: Array,
			required: true,
		},
	},
}
</script>
