<template>
	<a-drawer
		width="460px"
		:visible="showDrawer"
		:header-style="{ padding: '11px 24px' }"
		:closable="false"
		@close="onClose"
	>
		<div slot="title">
			<a-row type="flex" justify="space-between" align="middle">
				<a-col class="title">
					{{ title }}
					<div class="subheader">
						Data de vencimento {{ dueDate }}
					</div>
				</a-col>
				<a-col
					class="status"
					:style="{ 'background-color': statusColor }"
				>
					{{ status }}
				</a-col>
			</a-row>
		</div>
		<h3>Resumo da fatura</h3>
		<a-card
			title="Pagar ao Agiliza"
			size="small"
			class="resume-card"
			:head-style="resumeCardStyle"
			:body-style="{ padding: '0' }"
		>
			<span slot="extra">
				<MoneyValue
					class="card-extra"
					:value="toAgilizaValue"
					:size="14"
				/>
			</span>
			<a-list :data-source="toAgiliza">
				<a-list-item
					slot="renderItem"
					:key="item.key"
					slot-scope="item"
					style="padding: 4px 24px 0px 12px;"
				>
					<a-list-item-meta>
						<span slot="title" style="font-weight: 400;">{{
							item.name
						}}</span>
					</a-list-item-meta>
					<MoneyValue :size="13" :value="invoice[item.key]" />
				</a-list-item>
			</a-list>
		</a-card>
		<a-card
			title="Receber do Agiliza"
			size="small"
			class="resume-card"
			:head-style="resumeCardStyle"
			:body-style="{ padding: '0' }"
		>
			<span slot="extra">
				<MoneyValue
					class="card-extra"
					:value="toMerchantValue"
					:size="14"
				/>
			</span>
			<a-list :data-source="toMerchant">
				<a-list-item
					slot="renderItem"
					:key="item.key"
					slot-scope="item"
					style="padding: 4px 24px 0px 12px;"
				>
					<a-list-item-meta>
						<span slot="title" style="font-weight: 400;">{{
							item.name
						}}</span>
					</a-list-item-meta>
					<MoneyValue :size="13" :value="invoice[item.key]" />
				</a-list-item>
			</a-list>
		</a-card>
		<a-card
			class="resume-card"
			style="background-color: #e8e8e8;"
			:body-style="{
				padding: '8px 16px',
				'font-size': '14px',
				'font-weight': '600',
				color: '#333',
			}"
		>
			<a-row type="flex" justify="space-between" align="middle">
				<a-col>
					Valor {{ status === 'Paga' ? 'pago' : 'a pagar' }}
				</a-col>
				<a-col>
					R$
					<span style="font-size: 22px; font-weight: bold;">
						{{ total }}
					</span>
				</a-col>
			</a-row>
		</a-card>
		<a-divider v-show="showBarCode" />
		<h3 v-show="showBarCode">Código de barras</h3>
		<a-card
			v-show="showBarCode"
			class="bar-code-container"
			size="small"
			:body-style="{
				padding: '0 0 24px',
			}"
		>
			<p class="bar-code">
				{{ invoice.bar_code }}
			</p>
			<span class="copy-button">
				<a-button
					v-clipboard:copy="invoice.bar_code"
					v-clipboard:success="onCopyBarCode"
					v-clipboard:error="errorOnCopyBarCode"
					type="primary"
					shape="round"
					icon="copy"
					style="font-weight: 500;"
				>
					Copiar
				</a-button>
			</span>
		</a-card>
		<a
			v-show="showBarCode"
			class="doc-button"
			:href="invoice.doc"
			target="_blank"
			rel="2ª via"
			download
		>
			BAIXAR 2ª VIA
		</a>
	</a-drawer>
</template>

<script>
import { MoneyValue } from '@/modules/partner/components'

const toAgiliza = [
	{
		key: 'to_agiliza_value',
		name: 'Pedidos finalizados',
	},
	{
		key: 'to_agiliza_canceled',
		name: 'Pedidos cancelados',
	},
	{
		key: 'to_agiliza_cashback',
		name: 'Cashback',
	},
]
const toMerchant = [
	{
		key: 'to_merchant_value',
		name: 'Pagos pelo app',
	},
	{
		key: 'to_merchant_cashback',
		name: 'Cashback',
	},
]

export default {
	name: 'DetailInvoiceDrawer',
	components: {
		MoneyValue,
	},
	data() {
		return {
			showDrawer: false,
			toAgiliza,
			toMerchant,
			invoice: {},
			resumeCardStyle: {
				'background-color': '#e8e8e8',
				color: '#333',
				'border-radius': '5px 5px 0 0',
			},
		}
	},
	computed: {
		title: function () {
			return `Fatura de  ${this.moment(this.invoice.due_date).format(
				'MMMM'
			)}`
		},
		status: function () {
			if (this.invoice.payment_date) return 'Paga'
			else if (this.invoice.calculated) return 'Aberta'
			else if (this.moment() > this.moment(this.invoice.due_date))
				return 'Em Atraso'
			else return 'Fechada'
		},
		dueDate: function () {
			return this.moment(this.invoice.due_date).format('DD/MM/YYYY')
		},
		statusColor: function () {
			var COLOR = {
				Aberta: '#888888',
				Fechada: 'orange',
				Paga: '#87d068',
				'Em Atraso': '#fa1e0e',
			}

			return COLOR[this.status]
		},
		toAgilizaValue: function () {
			return this.toAgiliza.reduce(
				(sum, curr) => sum + this.invoice[curr.key],
				0
			)
		},
		toMerchantValue: function () {
			return this.toMerchant.reduce(
				(sum, curr) => sum + this.invoice[curr.key],
				0
			)
		},
		total: function () {
			let value = this.toAgilizaValue - this.toMerchantValue

			if (value < 0) value = 0

			return new Intl.NumberFormat('pt-BR', {
				style: 'currency',
				currency: 'BRL',
			})
				.format(value)
				.replace(/R\$/g, '')
		},
		showBarCode: function () {
			return this.status !== 'Paga' && this.invoice.bar_code
		},
	},
	methods: {
		setShowDrawer(invoice) {
			this.showDrawer = true
			this.invoice = { ...invoice }
		},
		onClose() {
			this.showDrawer = false
		},
		onCopyBarCode() {
			this.$message.success({
				content: 'Código de barras copiado!',
				duration: 4,
			})
		},
		errorOnCopyBarCode() {
			this.$message.error({
				content: 'Não foi possível copiar',
				duration: 4,
			})
		},
	},
}
</script>

<style lang="less" scoped>
.title {
	font-size: 18px;
	color: rgba(0, 0, 0, 0.75);
}
.status {
	padding: 2px 12px;
	border-radius: 50px;
	color: #feffff;
	// background-color: #a6a9b6;
	font-size: 14px;
	font-weight: 500;
}
.subheader {
	margin-top: -2px;
	font-size: 12px;
	font-weight: 400;
	color: rgba(0, 0, 0, 0.45);
}

h3 {
	color: rgba(0, 0, 0, 0.75);
}

.resume-card {
	margin-top: 16px;
	margin-bottom: 8px;
	border-radius: 5px;
}

.card-extra {
	padding: 2px 12px;
	border-radius: 50px;
	background-color: #fff;
	font-size: 14px;
	font-weight: 500;
}

.file {
	font-size: 13px;
	color: #333;

	&:hover {
		color: orange !important;
	}
}

.bar-code-container {
	margin: 16px 0;
	border-radius: 5px;

	.bar-code {
		display: flex;
		margin: 24px 0px;
		font-weight: bold;
		font-size: 13px;
		justify-content: space-around;
		color: rgba(0, 0, 0, 0.8);
	}

	.copy-button {
		display: flex;
		justify-content: center;
	}
}

.doc-button {
	display: flex;
	justify-content: center;
	height: 40px;
	padding: 10px;
	align-content: center;
	border-radius: 5px;
	color: white;
	background-color: #e96d35;
	border-color: #e96d35;
	font-weight: 500;
	font-size: 12px;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	touch-action: manipulation;

	&:hover {
		background-color: #f57d31;
		border-color: #f57d31;
	}
}
</style>
