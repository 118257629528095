<template>
	<ReportCard
		:title="title"
		:width="300"
		:height="250"
		:footer="footer"
		date-mode="date"
		:loading="loading"
		@date-change="onDateChange"
	>
		<div class="card-container">
			<a-row>
				<span class="description">
					R$
					<span class="description-total-value">
						{{ totalValue }}
					</span>
				</span>
				<a-icon
					v-if="indicator"
					:type="caret.type"
					:style="{ color: caret.color }"
				/>
			</a-row>
			<a-row
				type="flex"
				justify="space-around"
				align="center"
				style="margin: 18px 0;"
			>
				<!-- <a-col span="8" class="payments">
					<a-tooltip>
						<template slot="title">
							Pagos pelo app
						</template>
						<a-icon
							type="mobile"
							style="font-size: 22px; margin-bottom: 8px;"
						/>
						<MoneyValue :value="cardData['Pagos ao Agiliza']" />
						<span v-if="hasOrders" class="percent">
							{{ getPercent(cardData['Pagos ao Agiliza']) }}
						</span>
					</a-tooltip>
				</a-col> -->
				<a-col span="8" class="payments">
					<a-tooltip>
						<template slot="title">
							Pagos em dinheiro
						</template>
						<a-space direction="vertical" :size="4">
							<a-icon
								type="dollar"
								style="font-size: 22px; margin-bottom: 8px;"
							/>
							<MoneyValue
								:value="cardData['Pagos em Dinheiro']"
							/>
							<span v-if="hasOrders" class="percent">
								{{ getPercent(cardData['Pagos em Dinheiro']) }}
							</span>
						</a-space>
					</a-tooltip>
				</a-col>
				<a-col span="8" class="payments">
					<a-tooltip>
						<template slot="title">
							Pagos com cartão
						</template>
						<a-space direction="vertical" :size="4">
							<a-icon
								type="credit-card"
								style="font-size: 22px; margin-bottom: 8px;"
							/>
							<MoneyValue :value="cardData['Pagos com Cartão']" />
							<span v-if="hasOrders" class="percent">
								{{ getPercent(cardData['Pagos com Cartão']) }}
							</span>
						</a-space>
					</a-tooltip>
				</a-col>
			</a-row>
			<a-row type="flex" justify="end" :gutter="16">
				<a-col>
					<span>Nº de pedidos:</span>
				</a-col>
				<a-col>
					<a-tooltip>
						<template slot="title">
							Finalizados
						</template>
						<a-icon
							type="check-circle"
							theme="filled"
							style="margin-right: 4px; color: #87d068;"
						/>
						<span>{{ cardData['Finalizados'] }}</span>
					</a-tooltip>
				</a-col>
				<a-col>
					<a-tooltip>
						<template slot="title">
							Cancelados
						</template>
						<a-icon
							type="close-circle"
							theme="filled"
							style="margin-right: 4px; color: #f50;"
						/>
						<span>{{ cardData['Cancelados'] }}</span>
					</a-tooltip>
				</a-col>
			</a-row>
		</div>
	</ReportCard>
</template>

<script>
import ReportCard from './reportCard'
import { MoneyValue } from '@/modules/partner/components'
import { axios } from '@/utils/axios'
import { mapGetters } from 'vuex'

export default {
	name: 'DailyCard',
	components: {
		ReportCard,
		MoneyValue,
	},
	data() {
		return {
			loading: false,
			day: 'Hoje',
			cardData: {},
			footerData: {
				label: '',
				total: 0,
			},
			indicator: null,
		}
	},
	computed: {
		...mapGetters({
			isMerchantActive: 'merchants/isMerchantActive',
			isAuthorized: 'merchants/isAuthorized',
			selectedMerchantId: 'merchants/selectedMerchantId',
		}),
		caret: {
			set: function (values) {
				const { current, previus } = values

				if (current === previus) this.indicator = null
				else if (current > previus) {
					this.indicator = {
						type: 'caret-up',
						color: '#87d068',
					}
				} else {
					this.indicator = {
						type: 'caret-down',
						color: '#f50',
					}
				}
			},
			get: function () {
				return this.indicator
			},
		},
		totalValue: function () {
			return new Intl.NumberFormat('pt-BR', {
				style: 'currency',
				currency: 'BRL',
			})
				.format(this.cardData['Valor total acumulado'])
				.replace(/R\$/g, '')
		},
		footer: {
			set: function (data) {
				this.footerData = { ...data }
			},
			get: function () {
				return this.footerData
			},
		},
		hasOrders: function () {
			return this.cardData['Nº total de pedidos'] > 0
		},
		title: function () {
			return this.day
		},
	},
	beforeMount() {
		if (this.isAuthorized && this.isMerchantActive) this.getData()
	},
	methods: {
		async getData(date = null) {
			try {
				this.loading = true

				const url = `v1/merchants/${this.selectedMerchantId}/reports/financial/`
				const params = this.getDateRange(date)

				this.cardData = await axios.get(url, { params: params })
				this.day = date !== null ? this.getDateLabel(date) : 'Hoje'

				this.footer = {
					label: date ? this.getYesterdayLabel(date) : 'Ontem',
					total: this.cardData['Ontem'],
				}

				this.caret = {
					current: this.cardData['Valor total acumulado'],
					previus: this.cardData['Ontem'],
				}
			} catch (err) {
				//
			} finally {
				this.loading = false
			}
		},
		getDateRange(date) {
			if (date) {
				return {
					date_initial: date,
					date_final: date,
				}
			}

			const now = new Date()
			const year = now.getFullYear()
			const month = now.getMonth() + 1
			const day = now.getDate()

			return {
				date_initial: `${year}/${month}/${day}`,
				date_final: `${year}/${month}/${day}`,
			}
		},
		getPercent(value) {
			const percent =
				(value / this.cardData['Valor total acumulado']) * 100
			return `${percent.toFixed(0)}%`
		},
		onDateChange(date) {
			this.getData(date)
		},
		getDateLabel(date) {
			const options = { year: 'numeric', month: 'short', day: 'numeric' }

			return new Date(date).toLocaleString('pt-BR', options)
		},
		getYesterdayLabel(date) {
			return this.getDateLabel(new Date(date) - 1)
		},
	},
}
</script>

<style lang="less" scoped>
.card-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.description {
		font-size: 12px;
		color: rgba(0, 0, 0, 0.45);
		line-height: 1;

		.description-total-value {
			font-size: 18px;
			font-weight: bold;
			color: rgba(0, 0, 0, 0.75);
		}
	}

	.payments {
		display: flex;
		flex-direction: column;
		text-align: center;

		.percent {
			font-size: 12px;
			font-weight: 501;
			color: rgba(0, 0, 0, 0.45);
			line-height: 1;
		}
	}
}
</style>
