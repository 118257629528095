<template>
	<ReportCard
		title="Durante o ano"
		:width="632"
		:height="300"
		:footer="footer"
		date-mode="year"
		:loading="loading"
	>
		<VeHistogram
			:data="chartData"
			:settings="chartSettings"
			width="100%"
			height="310px"
			:extend="chartExtend"
			:colors="colors"
		/>
	</ReportCard>
</template>

<script>
import ReportCard from './reportCard'
import VeHistogram from 'v-charts/lib/histogram.common'
import { axios } from '@/utils/axios'
import { mapGetters } from 'vuex'

export default {
	name: 'YearlyCard',
	components: {
		ReportCard,
		VeHistogram,
	},
	data() {
		this.chartExtend = {
			grid: {
				show: false,
				top: 15,
				height: '190px',
			},
			legend: {
				show: false,
			},
		}
		return {
			loading: false,
			cardData: {},
			footerData: {
				label: '',
				total: 0,
			},
			chartSettings: {
				metrics: ['dinheiro', 'cartão'],
				stack: { sales: ['app', 'dinheiro', 'cartão'] },
			},
			chartData: {
				columns: ['mês', 'app', 'dinheiro', 'cartão'],
				rows: [],
			},
			colors: ['#ffb980', '#6be6c1', '#3fb1e6', '#e01f54', '#c4ebad'],
		}
	},
	computed: {
		...mapGetters({
			isMerchantActive: 'merchants/isMerchantActive',
			isAuthorized: 'merchants/isAuthorized',
			selectedMerchantId: 'merchants/selectedMerchantId',
		}),
		monthlyAvg: function () {
			let count = 0
			const sum = this.chartData.rows.reduce((sum, curr) => {
				const total = curr.app + curr['cartão'] + curr['dinheiro']
				if (total > 0) count++

				return (sum += total)
			}, 0)

			return count > 0 ? sum / count : 0
		},
		footer: function () {
			return {
				label: 'Média mensal',
				total: this.monthlyAvg,
			}
		},
	},
	watch: {
		$route: {
			immediate: true,
			handler: 'getData',
		},
	},
	methods: {
		async getData() {
			try {
				this.loading = true

				const url = `v1/merchants/${this.selectedMerchantId}/reports/annual_financial/`

				this.cardData = await axios.get(url)
				this.chartData.rows = [...this.cardData.annual_financial_report]
			} finally {
				this.loading = false
			}
		},
	},
}
</script>
