<template>
	<div class="container">
		<div class="header">
			<a-row type="flex" justify="space-between" :gutter="16">
				<a-col v-for="column in columns" :key="column.key" :span="4">
					<span class="title">
						{{ column.title }}
					</span>
				</a-col>
			</a-row>
		</div>
		<div class="body">
			<a-row
				v-for="element in invoices"
				:key="element.key"
				type="flex"
				justify="space-between"
				:gutter="16"
				class="row"
			>
				<a-col v-for="column in columns" :key="column.key" :span="4">
					<span v-if="column.key === 'due_date'" class="title">
						{{ moment(element.due_date).format('DD/MM/YYYY') }}
					</span>
					<span v-else-if="column.key === 'money'">
						{{ getValue(element) }}
					</span>
					<span v-else-if="column.key === 'status'">
						<a-tag color="green">
							Paga
						</a-tag>
					</span>
					<span v-else-if="column.key === 'info'" class="info">
						<a-tooltip>
							<template slot="title">
								Detalhar fatura
							</template>
							<span @click="$emit('detail', element.due_date)">
								Detalhar
							</span>
						</a-tooltip>
					</span>
					<span v-else-if="column.key === 'doc'">
						<a-tooltip>
							<template slot="title">
								Baixar PDF
							</template>
							<a
								class="file"
								:href="element.doc"
								download
								target="_blank"
							>
								<a-icon type="file-pdf" />
							</a>
						</a-tooltip>
					</span>
				</a-col>
			</a-row>
		</div>
		<div class="pagination">
			<a-pagination
				v-model="currentPage"
				size="small"
				:total="data.length"
				:page-size="8"
			/>
		</div>
	</div>
</template>

<script>
const columns = [
	{
		key: 'due_date',
		title: 'Vencimento',
	},
	{
		key: 'money',
		title: 'Valor',
	},
	{
		key: 'status',
		title: 'Status',
	},
	{
		key: 'doc',
		title: '2ª Via',
	},
	{
		key: 'info',
		title: '',
	},
]

export default {
	name: 'InvoicesHistoryTable',
	props: {
		data: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			columns,
			currentPage: 1,
		}
	},
	computed: {
		invoices: function () {
			return this.data.slice(
				(this.currentPage - 1) * 8,
				this.currentPage * 8
			)
		},
	},
	methods: {
		getValue(element) {
			const add = [
				'to_agiliza_value',
				'to_agiliza_cashback',
				'to_agiliza_canceled',
			]
			const subtract = ['to_merchant_value', 'to_merchant_cashback']

			let value = add.reduce((sum, curr) => sum + element[curr], 0)
			value -= subtract.reduce((sum, curr) => sum + element[curr], 0)

			if (value < 0) value = 0

			return new Intl.NumberFormat('pt-BR', {
				style: 'currency',
				currency: 'BRL',
			}).format(value)
		},
	},
}
</script>

<style lang="less" scoped>
.container {
	width: 100%;
	padding: 8px 0;

	.header {
		padding-bottom: 8px;
		border-bottom: 1px solid #e8e8e8;
		.title {
			font-size: 14px;
			font-weight: 501;
		}
	}
	.body {
		border-bottom: 1px solid #e8e8e8;
		min-height: 260px;
		font-size: 13px;

		.row {
			padding: 6px 0;

			&:hover {
				background-color: #fff7f0;
			}

			.title {
				font-weight: 501;
			}

			.file {
				font-size: 14px;
				color: #fa0f00;
				margin-left: 12px;
			}

			.info {
				padding: 0;
				font-weight: 500;
				height: 18px !important;
				line-height: 0;
				cursor: pointer;
				color: orange;

				&:hover {
					color: #f57d31;
				}
			}
		}
	}
	.pagination {
		display: flex;
		justify-content: flex-end;
		padding: 8px 0;
	}
}
</style>
