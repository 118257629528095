<template>
	<ReportCard title="Histórico anual" :width="360" :height="400">
		<VeBar
			width="100%"
			:data="chartData"
			:settings="chartSettings"
			:extend="chartExtend"
			:colors="colors"
		/>
	</ReportCard>
</template>

<script>
import ReportCard from './reportCard'
import VeBar from 'v-charts/lib/bar.common'

export default {
	name: 'AnnualHistoryCard',
	components: {
		ReportCard,
		VeBar,
	},
	props: {
		invoices: {
			type: Array,
			required: true,
		},
	},
	data() {
		this.chartExtend = {
			grid: {
				show: false,
				top: 15,
				left: 0,
				width: '90%',
			},
			legend: {
				show: false,
			},
		}
		this.chartSettings = {
			stack: {
				total: ['receber', 'pagar'],
			},
		}
		return {
			chartData: {
				columns: ['month', 'receber', 'pagar'],
				rows: [],
			},
			colors: ['#ffb980', '#6be6c1', '#3fb1e6', '#e01f54', '#c4ebad'],
		}
	},
	watch: {
		invoices: function () {
			this.chartData.rows = this.getRowsData()
		},
	},
	beforeMount: function () {
		this.chartData.rows = this.getRowsData()
	},
	methods: {
		getRowsData: function () {
			const add = [
				'to_agiliza_value',
				'to_agiliza_cashback',
				'to_agiliza_canceled',
			]
			const sub = ['to_merchant_value', 'to_merchant_cashback']

			return this.invoices.map((invoice) => {
				return {
					month: this.moment(invoice.due_date).format('MMM'),
					pagar: add.reduce((sum, curr) => sum + invoice[curr], 0),
					receber: sub.reduce((sum, curr) => sum + invoice[curr], 0),
				}
			})
		},
	},
}
</script>
