<template>
	<a-card
		:style="CardStyle"
		:body-style="{ padding: '16px', height: '100%' }"
	>
		<a-skeleton :loading="loading" active>
			<a-row type="flex" justify="space-between">
				<a-col class="header">{{ title }}</a-col>
				<a-col>
					<a-date-picker
						v-if="dateMode === 'date'"
						format="YYYY/MM/DD"
						:disabled-date="disabledDate"
						placeholder="Selecione o dia"
						:locale="locale"
						:mode="dateMode"
						@change="onDateChange"
					>
						<span class="datepicker-bottom">
							<a-icon type="ellipsis" />
						</span>
					</a-date-picker>
				</a-col>
			</a-row>
			<a-row type="flex" class="main">
				<slot></slot>
			</a-row>
			<div v-if="footer" type="flex" class="footer">
				<a-row type="flex" justify="space-between">
					<a-col>{{ `${footer.label}:` }}</a-col>
					<a-col>
						<MoneyValue :value="footer.total" />
					</a-col>
				</a-row>
			</div>
		</a-skeleton>
	</a-card>
</template>

<script>
// import moment from 'moment'
// import 'moment/locale/pt-br';
import locale from 'ant-design-vue/es/date-picker/locale/pt_BR'
import { MoneyValue } from '@/modules/partner/components'

export default {
	name: 'ReportCard',
	components: {
		MoneyValue,
	},
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			required: true,
		},
		width: {
			type: Number,
			default: 200,
		},
		height: {
			type: Number,
			default: 200,
		},
		footer: {
			type: Object,
			default: function () {
				return null
			},
		},
		dateMode: {
			type: String,
			default: null,
			validator: function (mode) {
				return ['date', 'month', 'year'].indexOf(mode) !== -1
			},
		},
	},
	data() {
		return {
			locale,
			CardStyle: {
				width: `${this.width}px`,
				height: `${this.height}px`,
				BorderRadius: '5px',
			},
		}
	},
	methods: {
		disabledDate(current) {
			return current > this.moment().endOf('day')
		},
		onDateChange(date, dateString) {
			this.$emit('date-change', dateString)
		},
	},
}
</script>

<style lang="less" scoped>
.header {
	color: rgba(0, 0, 0, 0.5);
}

.datepicker-bottom {
	font-size: 23px;
	font-weight: bold;
	cursor: pointer;
	line-height: 0.5;
}

.footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 40px;
	border-top: 1px solid #e8e8e8;
	padding: 8px 16px;
	vertical-align: center;
	left: 0;
	background: #fdfdfd;
	border-radius: 0 0 4px 4px;
}
</style>
