<template>
	<a-card class="card" :body-style="{ padding: '16px' }">
		<a-row type="flex" justify="start" :gutter="8">
			<a-skeleton :loading="loading" active>
				<a-col span="11">
					<a-row
						type="flex"
						justify="space-between"
						style="flex-direction: column;"
					>
						<a-col class="element">
							<span class="label">Valor total acumulado</span>
							<span class="value">
								R$
								<span class="total-value">
									{{ totalValue }}
								</span>
							</span>
						</a-col>
						<a-col class="element">
							<span class="label">Nº total de pedidos</span>
							<span class="value">
								<span class="total-value">
									{{ ordersTotal }}
								</span>
							</span>
						</a-col>
						<a-col class="element">
							<span class="label">Média das avaliações</span>
							<span class="value">
								<a-icon type="star" style="font-size: 17px;" />
								<span class="total-value">
									{{ rating }} / 5
								</span>
							</span>
						</a-col>
					</a-row>
				</a-col>
				<a-col span="13" style="margin-left: -40px;">
					<VePie
						width="250px"
						height="210px"
						:data="chartData"
						:settings="chartSettings"
						:extend="chartExtend"
						:colors="colors"
					/>
				</a-col>
			</a-skeleton>
		</a-row>
	</a-card>
</template>

<script>
import VePie from 'v-charts/lib/pie.common'
import { axios } from '@/utils/axios'
import { mapGetters } from 'vuex'

export default {
	name: 'ResumeCard',
	components: {
		VePie,
	},
	data() {
		this.chartExtend = {
			legend: {
				show: false,
			},
		}
		this.chartSettings = {
			level: [
				['Dinheiro', 'Cartão'],
				['Cancelados', 'Finalizados'],
			],
			radius: 75,
			offsetY: 100,
		}
		return {
			loading: true,
			cardData: {},
			chartData: {
				columns: ['type', 'value'],
				rows: [],
			},
			colors: ['#ffb980', '#6be6c1', '#3fb1e6', '#e01f54', '#c4ebad'],
		}
	},
	computed: {
		...mapGetters({
			isMerchantActive: 'merchants/isMerchantActive',
			isAuthorized: 'merchants/isAuthorized',
			selectedMerchantId: 'merchants/selectedMerchantId',
		}),
		totalValue: function () {
			return new Intl.NumberFormat('pt-BR', {
				style: 'currency',
				currency: 'BRL',
			})
				.format(this.cardData['Valor total acumulado'])
				.replace(/R\$/g, '')
		},
		ordersTotal: function () {
			return new Intl.NumberFormat('pt-BR').format(
				this.cardData['Nº total de pedidos']
			)
		},
		rating: function () {
			return Number(this.cardData['Média das avaliações'])
				.toFixed(1)
				.replace(/\./g, ',')
		},
	},
	beforeMount() {
		if (this.isAuthorized && this.isMerchantActive) this.getData()
	},
	methods: {
		async getData() {
			try {
				this.loading = true

				const url = `v1/merchants/${this.selectedMerchantId}/reports/financial/`

				this.cardData = await axios.get(url)
				this.mountRows()
			} catch (err) {
				console.log(err)
			} finally {
				this.loading = false
			}
		},
		mountRows() {
			const keys = [
				'Cancelados',
				'Finalizados',
				'App',
				'Dinheiro',
				'Cartão',
			]

			this.chartData.rows = keys.map((key) => {
				return {
					type: key,
					value:
						key !== 'App'
							? this.cardData[key]
							: this.cardData['Agiliza'],
				}
			})
		},
	},
}
</script>

<style lang="less" scoped>
.card {
	width: 384px;
	height: 250px;
	border-radius: 5px;

	.element {
		text-align: center;
		margin: 8px;
		color: rgba(0, 0, 0, 0.45);

		.label {
			display: block;
		}

		.value {
			padding-top: 8px;
			font-size: 12px;
			line-height: 1.75;

			.total-value {
				font-size: 18px;
				font-weight: bold;
				color: rgba(0, 0, 0, 0.75);
			}
		}
	}
}
</style>
