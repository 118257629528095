<template>
	<a-card
		size="small"
		class="container"
		:body-style="{ padding: '8px 16px' }"
	>
		<span slot="title" class="title">Histórico de avaliações</span>
		<div slot="extra">
			<a-space size="small" direction="horizontal">
				<a-select
					style="width: 104px;"
					:default-value="filterOption"
					@change="changeFilter"
				>
					<a-select-option
						v-for="option in filterOptions"
						:key="option.key"
					>
						{{ option.text }}
					</a-select-option>
				</a-select>
				<a-range-picker
					:locale="locale"
					:ranges="{
						Hoje: [moment(), moment()],
						'Este mês': [moment().startOf('month'), moment()],
						'Este ano': [moment().startOf('year'), moment()],
					}"
					format="DD/MM/YYYY"
					:disabled-date="disableFutureDates"
					:default-value="dates"
					@change="changeDates"
				>
					<a-icon slot="suffixIcon" type="calendar" />
				</a-range-picker>
			</a-space>
		</div>
		<div class="body">
			<a-list
				v-if="loading || isLoading"
				item-layout="vertical"
				:data-source="ratingsList"
			>
				<a-list-item slot="renderItem" key="item.title">
					<a-space
						direction="vertical"
						size="large"
						style="width: 100%;"
					>
						<a-skeleton
							:loading="true"
							:title="false"
							:paragraph="{ rows: 2, width: ['100%', '65%'] }"
							active
							avatar
						/>
						<a-skeleton
							:loading="true"
							:title="false"
							:paragraph="{ rows: 1, width: 'calc(100% - 56px)' }"
							active
							style="margin-left: 56px;"
						/>
					</a-space>
				</a-list-item>
			</a-list>
			<p v-else-if="ratingsList.length === 0" class="empty-list">
				Nenhuma avaliação foi encontrada
			</p>
			<a-list v-else item-layout="vertical" :data-source="ratingsList">
				<a-list-item
					slot="renderItem"
					key="item.title"
					slot-scope="item"
				>
					<a-list-item-meta
						:description="`Avaliado em ${getDate(item.rated_at)}`"
					>
						<a-avatar
							slot="avatar"
							:src="item.user_picture"
							icon="user"
							:size="48"
						/>
						<a-row
							slot="title"
							class="item-title"
							type="flex"
							justify="space-between"
							align="top"
						>
							<a-col>
								<span>{{ item.user_name }}</span>
							</a-col>
							<a-col>
								<span
									style="
										display: flex;
										justify-content: flex-end;
									"
								>
									<a-space align="center">
										<a-rate
											:value="item.rating_value"
											style="font-size: 16px;"
											disabled
											allow-half
										/>
									</a-space>
								</span>
							</a-col>
						</a-row>
					</a-list-item-meta>
					<div class="content">
						{{ item.rating_text }}
					</div>
				</a-list-item>
			</a-list>
			<InfiniteLoading
				slot="append"
				ref="infiniteLoading"
				force-use-infinite-wrapper=".body__body-wrapper"
				@infinite="loadMore"
			>
				<div slot="spinner"></div>
				<div slot="no-more"></div>
				<div slot="no-results"></div>
			</InfiniteLoading>
		</div>
	</a-card>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import locale from 'ant-design-vue/es/date-picker/locale/pt_BR'

const mockData = Array.from({ length: 5 }, (_, i) => i + 1)

const filterOptions = Array.from({ length: 6 }, (_, i) => ({
	key: i === 0 ? 'all' : String(i),
	text: i === 0 ? 'Todos' : i === 1 ? `${i} estrela` : `${i} estrelas`,
}))

export default {
	name: 'RatingsResume',
	components: {
		InfiniteLoading,
	},
	data() {
		return {
			locale,
			mockData,
			filterOptions,
			currentPage: 1,
			isLoading: false,
		}
	},
	computed: {
		...mapGetters({
			isMerchantActive: 'merchants/isMerchantActive',
			isAuthorized: 'merchants/isAuthorized',
			selectedMerchantId: 'merchants/selectedMerchantId',
			loading: 'merchants/loadingRatings',
			ratingsList: 'merchants/ratingsList',
			dates: 'ux/ratingsRangeDates',
			filterOption: 'ux/ratingsFilterOption',
		}),
	},
	methods: {
		...mapActions('merchants', ['getRatingsList']),
		...mapMutations('ux', [
			'setRatingsRangeDates',
			'setRatingsFilterOption',
		]),
		async loadData(payload, $state) {
			if (!this.isAuthorized || !this.isMerchantActive) return

			try {
				this.isLoading = true
				const { next } = await this.getRatingsList(payload)

				if (next !== null) {
					this.currentPage++
					$state.loaded()
				} else {
					$state.complete()
				}
			} catch (error) {
				console.log(error)
			} finally {
				this.isLoading = false
			}
		},
		getDate(date) {
			const options = { year: 'numeric', month: 'short', day: 'numeric' }
			return new Date(date).toLocaleString('pt-BR', options)
		},
		disableFutureDates(current) {
			return current > this.moment().endOf('day')
		},
		changeDates(dates) {
			this.currentPage = 1
			this.setRatingsRangeDates(dates)
			this.$refs.infiniteLoading.stateChanger.reset()
		},
		changeFilter(option) {
			this.setRatingsFilterOption(option)
			this.$refs.infiniteLoading.stateChanger.reset()
		},
		loadMore($state) {
			const dateFormat = 'YYYY/MM/DD'
			const [inicial, final] = this.dates
			const stars =
				this.filterOption !== 'all' ? this.filterOption : undefined

			const payload = {
				merchantId: this.selectedMerchantId,
				params: {
					page: this.currentPage,
					page_size: 10,
					date_inicial: inicial?.format(dateFormat) || undefined,
					date_final: final?.format(dateFormat) || undefined,
					stars,
				},
			}

			this.loadData(payload, $state)
		},
	},
}
</script>

<style lang="less" scoped>
@import url('@/config/globalStyles');
.container {
	width: 100%;
	border-radius: 5px;

	.title {
		font-size: 16px;
	}

	.item-title {
		margin-bottom: -12px;
	}
	.body {
		.content {
			margin: -12px 0 0 64px;
			padding-top: 16px;
			border-top: 1px dashed #e8e8e8;
		}
	}
}
</style>
